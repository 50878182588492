/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
const Kronicle = require('../../../assets/Kronicle.gif');

 function BackdropSimple() {
  return (
    <img alt='kronicle' style={{display:"block",width: "100%"}} src={String(Kronicle)} />
  );
}

export default BackdropSimple
