/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'; 
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

export default function MandateForm1() {
    const [activeStep, setActiveStep] = React.useState(0);






    
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    // const handleReset = () => {
    //   setActiveStep(0);
    // };
  const [DateValue, setValue] = React.useState<Date | null>(null);
  const [reference, setReference] = React.useState('');
  
  const [attn, setAttn] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [clientAddress, setClientAddress] = React.useState('');
  const [clientEmail, setClientEmail] = React.useState('');
  const [clientPhone, setClientPhone] = React.useState('');

  const [PHF, setPHF] = React.useState('');
  const [SAHF, setSAHF] = React.useState('');
  const [AHF, setAHF] = React.useState('');
  const [JAHF, setJAHF] = React.useState('');

  const [advanceAmt, setAdvanceAmt] = React.useState('');
  const [advanceAmtWord, setAdvanceAmtWord] = React.useState('');

  const [rPercentage, setRpercentage] = React.useState('');
  // const [generateAlert, setGenerateAlert] = React.useState('')

  // const today=new Date();
  
  
  
  const editedData=[
    {name:"<<Date of Mandate>>",value:DateValue},
    {name:"<<Your Ref:>>",value:reference},

    {name:"<<Attn:>>",value:attn},
    {name:"<<Client Name>>",value:clientName},
    {name:"<<Client Address>>",value:clientAddress},
    {name:"<<Client Email Id>>",value:clientEmail},
    {name:"<<Client Phone no>>",value:clientPhone},

    {name:"<<Partner Hourly Fee>>",value:PHF},
    {name:"<<Sr. Associate Hourly Fee>>",value:SAHF},
    {name:"<<Associate Hourly Fee>>",value:AHF},
    {name:"<< Jr. Associate Hourly Fee>>",value:JAHF},

    {name:"<<Advance Amount>>",value:advanceAmt},
    {name:"<<Advanceamountinwords>>",value:advanceAmtWord},

    {name:"<<Renewal percentage>>",value:rPercentage},

    ]
       
    const changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReference(event.target.value);
    };


    const changeAttn = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAttn(event.target.value);
    };
    const changeClientName = (event: React.ChangeEvent<HTMLInputElement>) => {
      setClientName(event.target.value);
    };
    const changeClientAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
      setClientAddress(event.target.value);
    };
    const changeClientPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
      setClientPhone(event.target.value);
    };
    const changeClientEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
      setClientEmail(event.target.value);
    };



    const changeAHF = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAHF(event.target.value);
    };
    const changePHF = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPHF(event.target.value);
    };
    const changeSAHF = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSAHF(event.target.value);
    };
    const changeJAHF = (event: React.ChangeEvent<HTMLInputElement>) => {
      setJAHF(event.target.value);
    };


    const changeAdvanceAmt = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdvanceAmt(event.target.value);
    };
    const changeAdvanceAmtWord = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdvanceAmtWord(event.target.value);
    };



    const changeRpecentage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRpercentage(event.target.value);
    };
    // const changeGenerateAlert = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setGenerateAlert(event.target.value);
    // };



  
  const editDocs=()=>{
    var DD = String(DateValue.getDate()).padStart(2, '0');
    var MM = String(DateValue.getMonth() + 1).padStart(2, '0'); //January is 0!
    var YYYY = DateValue.getFullYear();
    var dvalue = DD + '/' + MM + '/' + YYYY;

    editedData[0].value=dvalue;
    console.log(editedData)
     Word.run(async (context) => {
        for (var d of editedData) {
            var searchResults = context.document.body.search(d.name, { ignorePunct: true });
            context.load(searchResults, 'text,font');
            await context.sync();
            searchResults.items.forEach(function (item) {
                item.insertText(d.value as string, Word.InsertLocation.replace);
                item.font.color='#000'
            });
            await context.sync();
        }
      
  })
  }

  return (

    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
      
          <Step >
            <StepLabel>
            Mandate Details
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" label="Your Ref." value={reference} onChange={changeReference} size="small" />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker label="Date Of Mandate"  value={DateValue} onChange={(newValue) => { setValue(newValue);}} renderInput={(params) => <TextField size="small" {...params} />}/>
            </LocalizationProvider>
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={reference === '' || DateValue === null}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step >
            <StepLabel>
            Address Details
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" label="Attn." value={attn} onChange={changeAttn} size="small" />
              <TextField id="outlined-name" label="Client Name" value={clientName} onChange={changeClientName} size="small"/>
              <TextField id="outlined-multiline-static" label="Client Address" multiline rows={2} value={clientAddress} onChange={changeClientAddress} />
              <TextField id="outlined-name" type='email' label="Client Email Id" value={clientEmail} onChange={changeClientEmail} size="small"/>
              <TextField id="outlined-name" type='number' label="Client phone No." value={clientPhone} onChange={changeClientPhone} size="small"/>
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                  <Button
                    disabled={attn === '' || clientName === '' || clientAddress==='' || clientEmail==='' || clientPhone===''}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step >
            <StepLabel>
            Remuneration (Time Based Billing)
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" type='number' label="Partner Hourly Fee" value={PHF} onChange={changePHF} size="small" />
              <TextField id="outlined-name" type='number' label="Sr. Associate Hourly Fee" value={SAHF} onChange={changeSAHF} size="small"/>
              <TextField id="outlined-name" type='number' label="Associate Hourly Fee" value={AHF} onChange={changeAHF} size="small"/>
              <TextField id="outlined-name" type='number' label="Jr. Associate Hourly Fee" value={JAHF} onChange={changeJAHF} size="small"/>
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                  <Button
                    disabled={reference === '' || DateValue === null}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step >
            <StepLabel>
            Advance
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" type='number' label="Advance Amount" value={advanceAmt} onChange={changeAdvanceAmt} size="small" />
              <TextField id="outlined-name" label="Advance Amount in words" value={advanceAmtWord} onChange={changeAdvanceAmtWord} size="small"/>
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                  <Button
                    disabled={reference === '' || DateValue === null}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step >
            <StepLabel>
            Terms & Renewal
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" label="Renewal percentage" value={rPercentage} onChange={changeRpecentage} size="small" />
              {/* <TextField id="outlined-name" label="generate alert 30 days before expiry of the term" value={generateAlert} onChange={changeGenerateAlert} size="small"/> */}
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                <Button variant="contained" 
                disabled={rPercentage===''} 
                sx={{ mt: 1, mr: 1 }} onClick={editDocs}>update</Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>
        
      </Stepper>

    </Box>
  );
}
