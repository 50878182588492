/* eslint-disable prettier/prettier */
import * as React from "react";
// import "./styles.css";
// import Home from "./screens/Home";
// import About from "./screens/About";
// import { Route, Switch,BrowserRouter,Redirect } from "react-router-dom";
// import Jobs from "./screens/Jobs";
// import Engineer from "./screens/Engineer";
// import Marketer from "./screens/Marketerer";
// import Designer from "./screens/Designer";
import { Routes } from "./routing/Routes";


export default function App() {
  return (
    <>
    <Routes />
    </>
  );
}
