/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
// import axios from "axios"
import { DefaultButton } from '@fluentui/react/lib/Button';
// import Button from '@material-ui/core/Button';
import { Redirect} from 'react-router-dom';
import Loader from "./Loader"
import Typography from '@mui/material/Typography';
// import { mergeStyles } from '@fluentui/react/lib/Styling';
// import { FontIcon } from '@fluentui/react/lib/Icon';

// const iconClass = mergeStyles({
//     fontSize: '18px',
//     height: 18,
//     width: 18,
//     margin:'0 15px',
//     color:'#FFFFFF'
//   });

 function LandingPage() {
    const [open, setOpen] = React.useState(false);
    const [loaderFlag, setLoaderFlag] = React.useState(false);

    const encodeBase64=(docData)=> {
        var s = "";
        for (var i = 0; i < docData.length; i++)
            s += String.fromCharCode(docData[i]);
        return window.btoa(s);
      }
   
    const getFileAsyncInternal=async ()=> {
      setLoaderFlag(true); 
        Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 4194304  /*64 KB*/  }, 
          
          function (asyncResult) {
            console.log(JSON.stringify(asyncResult))
          
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                document.getElementById("log").textContent = JSON.stringify(asyncResult);
            }
            else {
                getAllSlices(asyncResult.value);
            }
        });
      } 
    const getAllSlices= async (file) => {
      var sliceCount = file.sliceCount;
      var sliceIndex = 0;
      var docdata = [];
      var getSlice = function () {
          file.getSliceAsync(sliceIndex, function (asyncResult) {
              if (asyncResult.status == "succeeded") {
                  docdata = docdata.concat(asyncResult.value.data);
                  sliceIndex++;
                  if (sliceIndex == sliceCount) {
                      file.closeAsync();
                      onGetAllSlicesSucceeded(docdata);
                  }
                  else {
                      getSlice();
                  }
              }
              else {
                  file.closeAsync();
                  document.getElementById("log").textContent = JSON.stringify(asyncResult);
              } 
          });
      };
      getSlice();
    }
    const onGetAllSlicesSucceeded= async (docxData) =>{
        var base64Data=encodeBase64(docxData)
        localStorage.setItem('dData', base64Data);
        var assessData=[]
        var indexData=[{"headings":"API error"}]
        console.log(base64Data)
        var payload={}
        payload['documentData']=base64Data; 
        await fetch("https://apis.digiebooks.in/kronicle/api/assess/",{
          method: 'PUT', 
          body:JSON.stringify(payload)
          }).then((response) => response.json()) 
            .then((response) => {
              if(response?.data){
                // console.log("data from response-->>>>\n",JSON.stringify(response.data.data))
                assessData=response.data.data;
                localStorage.setItem('assessData',JSON.stringify(assessData));
                }else{
                  localStorage.setItem('indexData',JSON.stringify(indexData));
                }
  
             fetch("https://apis.digiebooks.in/kronicle/api/agreementindex/",{
              method: 'PUT', 
              body:JSON.stringify(payload)
              }).then((response) => response.json()) 
                .then((response) => {
                  console.log("aggreement RESPONSE ",JSON.stringify(response))
                    if(response?.data){
                    indexData=response.data;
                    localStorage.setItem('indexData',JSON.stringify(response?.data));
                    }else{
                      localStorage.setItem('indexData',JSON.stringify(indexData));
                    }
                    setOpen(true); 
                // this.setState({indexData:response.data})
                // this.setState({backdropFlag:false})                                  
            })   
                // this.forceUpdate();                                    
         }) 
         

         
             
            //  })   
        
       }
       if (open) {
        return <Redirect to='/mainPage'/>;
      }
      else if(loaderFlag){
        return<Loader />;
      }

  return (
    <div>
      <div className="getStart">
        <img width="220" height="200" src="assets/1.png" />
        <div style={{paddingTop:"100px"}} >
        <Typography variant="caption" gutterBottom sx={{color:'white',}}>
        Contract authoring tool enabling users to navigate, assemble, edit, review and insert pre-approved clauses.
        </Typography>
        <DefaultButton onClick={getFileAsyncInternal} allowDisabledFocus open={false} >
        <span style={{color:'#587d9f',width:"250px"}}>Get Started</span>
        </DefaultButton>
        </div>
      </div>
    </div>
  );
}

export default LandingPage
