/* eslint-disable prettier/prettier */
import * as React from "react";
import { BrowserRouter,Switch, Route,Redirect} from "react-router-dom";
import GenericClause from '../GenericClause';
import Login from '../Login';
import Assess from "../Assess";
import LandingPage from "../landinpage";
import MainPage from "../MainPage"
import { Routing } from './routing';
// import Breadcrumb from "../screens/Breadcrumbs";


export const Routes: React.FC = () => (
    
<section>
<BrowserRouter>
  {/* <Breadcrumb /> */}
  <Switch> 
    <Route  exact
      path={Routing.GENERICCLOUSE.getPath()} 
      component={GenericClause}/>
    <Route 
      path={Routing.LOGIN.getPath()}
      component={Login}/>
    
    <Route 
      path={Routing.ASSESS.getPath()}
      component={Assess}/>
    
    <Route 
      path={Routing.MAINPAGE.getPath()}
      component={MainPage}/>
    
    <Route 
      path={Routing.LANDINGPAGE.getPath()}
      component={LandingPage}/>
    
   

    <Route component = {() => <Redirect to = "/" />} />
   
  </Switch>
</BrowserRouter>

</section>
);
