/* eslint-disable prettier/prettier */
import * as _ from 'lodash-es';

const appendPath = (...paths: (string|number)[]) => _.join(paths, '/');


// const GETTINGSTART = {
//   getPath: (): string => appendPath('/'),
// };
const LANDINGPAGE = {
  getPath: (): string => appendPath('/'),
};
const GENERICCLOUSE = {
  getPath: (): string => appendPath(
    '/genericClouse'
  ),
};

const LOGIN = {
  getPath: (): string => appendPath(
    '/login'
  ),
};
const ASSESS = {
  getPath: (): string => appendPath(
    '/assess'
  ),
};
const MAINPAGE = {
  getPath: (): string => appendPath(
    '/mainPage'
  ),
};
// const LANDINGPAGE = {
//   getPath: (): string => appendPath(
//     '/landingpage'
//   ),
// };

export const Routing = {
  GENERICCLOUSE,
  LOGIN,
  ASSESS,
  LANDINGPAGE,
  MAINPAGE
};
