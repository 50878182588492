/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'; 
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';


export default function NeutralForm() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    // const handleReset = () => {
    //   setActiveStep(0);
    // };
  const [DateValue, setValue] = React.useState<Date | null>(null);
  const [Transaction, setTransaction] = React.useState('');
  const [term, setTerm] = React.useState('');
  const [governingLawCountry, setGoverningLawCountry] = React.useState('');
  const [jurisdiction, setJurisdiction] = React.useState('');
  
  
  const [firstPartyName, setFName] = React.useState('');
  const [firstPartyAddress, setFAddress] = React.useState('');
  const [firstPartyCIN, setFCIN] = React.useState('');

  const [secondPartyName, setSName] = React.useState('');
  const [secondPartyAddress, setSAddress] = React.useState('');
  const [secondPartyCIN, setSCIN] = React.useState('');

  const today=new Date();
  
  
  
  const editedData=[
    {name:"<<EffectiveDate>>",value:DateValue},
    {name:"<<TransactionDetails>>",value:Transaction},
    {name:"<<Term>>",value:term},
    {name:"<<GoverningLawCountry>>",value:governingLawCountry},
    {name:"<<Jurisdiction>>",value:jurisdiction},

    {name:"<<FirstPartyName>>",value:firstPartyName},
    {name:"<<FirstPartyAddress>>",value:firstPartyAddress},
    {name:"<<FirstPartyCIN>>",value:firstPartyCIN},
    {name:"<<SecondPartyName>>",value:secondPartyName},
    {name:"<<SecondPartyAddress>>",value:secondPartyAddress},
    {name:"<<SecondPartyCIN>>",value:secondPartyCIN},
    
    ]
    
    const handleChangeTransactions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTransaction(event.target.value);
    };    
    const firstPname = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFName(event.target.value);
    };
    const firstPaddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFAddress(event.target.value);
    };
    const secondPname = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSName(event.target.value);
    };
    const secondPaddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSAddress(event.target.value);
    };
    const firstPCin= (event: React.ChangeEvent<HTMLInputElement>) => {
      setFCIN(event.target.value);
    };
    const secondPCin= (event: React.ChangeEvent<HTMLInputElement>) => {
      setSCIN(event.target.value);
    };
    
    const handleTerm= (event: React.ChangeEvent<HTMLInputElement>) => {
      setTerm(event.target.value);
    };
    const handleGoverningLawCountry= (event: React.ChangeEvent<HTMLInputElement>) => {
      setGoverningLawCountry(event.target.value);
    };
    const handleJurisdiction= (event: React.ChangeEvent<HTMLInputElement>) => {
      setJurisdiction(event.target.value);
    };
    
    
  
  const editDocs=()=>{
    var DD = String(DateValue.getDate()).padStart(2, '0');
    var MM = String(DateValue.getMonth() + 1).padStart(2, '0'); //January is 0!
    var YYYY = DateValue.getFullYear();
    var dvalue = DD + '/' + MM + '/' + YYYY;

    editedData[0].value=dvalue;
    console.log(editedData)
     Word.run(async (context) => {
        for (var d of editedData) {
            var searchResults = context.document.body.search(d.name, { ignorePunct: true });
            context.load(searchResults, 'text');
            await context.sync();
            searchResults.items.forEach(function (item) {
                item.insertText(d.value as string, Word.InsertLocation.replace);
            });
            await context.sync();
        }
      
  })
  }

  return (

    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
      
          <Step >
            <StepLabel>
            Enter First Party Details
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" label="Name" value={firstPartyName} onChange={firstPname} size="small" />
              <TextField id="outlined-name" label="CIN" value={firstPartyCIN} onChange={firstPCin} size="small"/>
              <TextField id="outlined-multiline-static" label="Address" multiline rows={2} value={firstPartyAddress} onChange={firstPaddress} />
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                
                  <Button
                    disabled={firstPartyName === '' || firstPartyAddress==='' || firstPartyCIN===''}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step >
            <StepLabel>
            Enter Second party Details
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
              <TextField id="outlined-name" label="Name" value={secondPartyName} onChange={secondPname} size="small" />
              <TextField id="outlined-name" label="CIN" value={secondPartyCIN} onChange={secondPCin} size="small" />
              <TextField id="outlined-multiline-static" label="Address" multiline rows={2} value={secondPartyAddress} onChange={secondPaddress} />
            </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                  <Button 
                   disabled={secondPartyName === '' || secondPartyCIN==='' || secondPartyAddress===''}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Next'}
                  </Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step >
            <StepLabel>
            Enter NDA Details
            </StepLabel>
            <StepContent>
            <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker label="Effective Date" minDate={today} value={DateValue} onChange={(newValue) => { setValue(newValue);}} renderInput={(params) => <TextField size="small" {...params} />}/>
            </LocalizationProvider>
            
            <TextField id="outlined-name" label="Transactions" value={Transaction} onChange={handleChangeTransactions} size="small"/>
            <TextField id="outlined-name" type='number' label="Term (years)" value={term} onChange={handleTerm} size="small"/>
            <TextField id="outlined-name" label="Governing Law Country" value={governingLawCountry} onChange={handleGoverningLawCountry} size="small"/>
            <TextField id="outlined-name" label="Jurisdiction" value={jurisdiction} onChange={handleJurisdiction} size="small"/>
            
            
                </Stack>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                <Button variant="contained" 
                disabled={DateValue === null || term==='' || Transaction==='' || governingLawCountry==='' || jurisdiction===''} 
                sx={{ mt: 1, mr: 1 }} onClick={editDocs}>update</Button>
                  
                </div>
              </Box>
            </StepContent>
          </Step>
        
      </Stepper>

    </Box>
  );
}
