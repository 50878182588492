/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import GenericClause from "./GenericClause";
import ClientClauses from "./ClientClauses"
import SellSideClause from './SellSide'
import BuySideClause from './BuySide'

export interface AdditorState {
    
  
}

export default class Additor extends React.Component<{},AdditorState> {

    constructor(props, context) {
        super(props, context);
        this.state={ 
            
        }
        
      }

  render() {
    return (
        <div>
        <GenericClause />
        <ClientClauses />
        <SellSideClause />
        <BuySideClause />
        </div>
    );
  }
}
