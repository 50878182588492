/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
const Kronicle = require('../../../assets/Kronicle.gif');
export interface LoginProps {
  apiData: string;
  
}

export default class Login extends React.Component<LoginProps> {

  render() {

    return (
    <img alt='kronicle' style={{ width: 320}} src={String(Kronicle)} />
    );
  }
}
