/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */

import * as React from "react";
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Link from '@mui/material/Link';
import ListSubheader from '@mui/material/ListSubheader';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Grid from '@mui/material/Grid';




const styles:StylesDictionary = { 
  navBar: { 
    //  backgroundColor: '#587d9f',
    //  backgroundColor: '#3E5580',
     fontSize:"14px",
     fontFamily:'barlow',
    fontWeight:500,
    // color:'#ffffff', 
    cursor:'pointer',
    paddingTop:"4px",
    paddingBottom:"4px",
    // paddingLeft:'10px',
    //  position:"sticky",
    //  top:"-1px",
    zIndex:1
  },
  navBar2: { 
    // backgroundColor: '#587d9f',
    //  color:'#ffffff',
    // paddingTop:"0px",
    fontFamily:'barlow',
    fontWeight:400,
    fontSize:'12px',
    zIndex:0,
    paddingLeft:'20px',
    lineHeight:1.1
  },
  navBar3: {
    // backgroundColor: '#70AFCE',
    color:'#000',
    // paddingTop:"0px",
    fontSize:'12px',
    zIndex:0 ,
    // paddingLeft:'20px',

  },
  children: {
    // paddingLeft:"20px",
    fontSize:'0.5rem'
  },
  rightNav: {
  },
  verticalLine: {
  },
  list:{
    fontSize:"0.4rem "
  } , 
  ColorBlack:{
    color:'#000'
  },
  ColorBlue:{
    // color:'blue'
  }
};



export interface AppProps {
  // title: string; 
  // isOfficeInitialized: boolean;
  // data:any
}
export interface StylesDictionary{
  [Key: string]: React.CSSProperties;
}

export interface AppState {
  

}
export interface IDropdownStates {
  singleValueDropdown: string;
}

export default class Assess extends React.Component<{}, AppState> {
  
  
  base64Data:string
 
  
  menuItems:any={ 
    "data" : []
  }
  crossData:any=[]

  definedTermArray
  
  state = {backdropFlag:false,
            expand:false,
            accordionFlag:false,
            breadcrumsFlag:false,
            itemValue:'',
            definedTermBody:false,
            definedName:'',
            definedDefinition:'',
          };
  constructor(props, context) {
    super(props, context); 
  }

  async componentDidMount(){
    const dData=localStorage.getItem('dData');
    const assessData=JSON.parse(localStorage.getItem('assessData'));
    this.base64Data=dData
    console.log("ASSESS DATA\n",JSON.stringify(assessData))
    this.menuItems.data=assessData
    var payload={} 
    payload['documentData']=dData
    this.setState({backdropFlag:true})
    // await fetch("https://apis.digiebooks.in/kronicle/api/assess/",{
    //   method: 'PUT', 
    //   body:JSON.stringify(payload)
    //   }).then((response) => response.json()) 
    //     .then((response) => {
    //     console.log("data from response-->>>>\n",JSON.stringify(response.data))
    //     this.menuItems.data=response.data.data;
    //     this.setState({backdropFlag:false})
    //     // this.forceUpdate();                                    
    //  })   

     Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, this.selectHandler);
  }
  selectHandler() {
      let item:any
      Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, function (asyncResult) {
        if (asyncResult.status == Office.AsyncResultStatus.Failed) {
            console.log('Action failed. Error: ' + asyncResult.error.message);
        }
        else {
            // console.log('Selected data: ' + asyncResult.value);
            item=asyncResult.value
            console.log(item,"\n") 
            // this.determineParent(this.menuItems.data,item)
            
        }
    });
  }
   determineParent(curNode, item) {
    if(curNode.name===item)
        return curNode; // found the parent node with the correct clickedTitle

    // not found yet, do a recusive search down the tree.

    for(const node of curNode.children) {
        if(node.name===item){
          this.setState(prevState => ({ 
            [item]: !prevState[item],
           }));
        }else{
        return this.determineParent(node,item);
        }
        
    }

    return null; // not found.
}
  handleAll(){
    this.setState(prevState => ({ 
      [this.state.itemValue]: !prevState[this.state.itemValue]
     }));
     this.setState({breadcrumsFlag:false})
  }
  crossRef(){
    const {definedName}=this.state
    var payload={}
        payload['documentData']=this.base64Data;
        payload['selectedDefinedTerm']=definedName;
        payload['rule']='cross_linked_reference';
        payload['definedTerms']=this.definedTermArray;
        // console.log(payload)
        fetch("https://apis.digiebooks.in/kronicle/api/assess/",{
        method: 'PUT', 
        body:JSON.stringify(payload)
        }).then((response) => response.json()) 
        .then((response) => {
        console.log("RESPONSE\n..............\n",JSON.stringify(response))
        this.crossData=response.data.data;
        this.forceUpdate()
        console.log(" Cross data from response-->>>>\n",JSON.stringify(this.crossData))
      })   
  }
  handleBreadcrumbs(){
    if(this.state.breadcrumsFlag){
    return(
    <div role="presentation" >
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" onClick={() => this.handleAll()} style={styles.navBar3}>
        Assess
      </Link>
      <Link
        underline="hover"
        color="inherit"
        
      >
        All
      </Link>
      {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
    </Breadcrumbs>
  </div>
    )
    }else{
      return(
        <></>
      )
    }
  }
  
  
  handleClick(item,index) {
    
    console.log(JSON.stringify(item))
    console.log(JSON.stringify(item.name))
    //  this.setState({breadcrumsFlag:!this.state.breadcrumsFlag,itemValue:item})
     this.definedTermArray=[]

      for(let i=0;i<this.menuItems.data[0].children.length;i++){
        this.definedTermArray.push(this.menuItems.data[0].children[i].name)
      }  
      // if(item.name !=='Defined Terms' && item.name !=='Undefined Terms' && item.name !=='Unused Defined Terms' && item.name !=='Inconsistencies' && item.name !=='Defined Terms not capitalised'){
        
      // }
     if(this.menuItems.data[0]?.children[index]?.name===item.name){
      this.setState({definedDefinition:item?.definitions,definedName:item?.name,definedTermBody:true})
      this.crossRef()
      // console.log(this.menuItems.data[0]?.children[searchIndex].name)
        
        
        // this.menuItems.data[0].children[index].children=[] 
        
        //   this.menuItems.data[0].children[index].children.push({"definitions":this.menuItems.data[0].children[index].definitions});
          
        
        // for(let j=0;j<response.data.data.length;j++){
        //   this.menuItems.data[0].children[index].children.push(response.data.data[j]);
        // }
          // this.menuItems.data=response.data.data;
        // console.log( JSON.stringify(this.menuItems.data))
        // this.forceUpdate();
        
                                           
       

     }
     if(item.name==='Cross-references' || item.name==='linked-references'){
      this.crossRef()
    }
     const inconsistencyIndex = this.menuItems.data.findIndex((i) => i.name==='Inconsistencies');
     console.log(inconsistencyIndex)
      if(this.menuItems.data[inconsistencyIndex]?.children[index]?.name===item.name){
        console.log('if')
        var param={}
        param['documentData']=this.base64Data;
        param['selectedDefinedTerm']=item.name;
        // param['rule']='cross_linked_reference';
        param['definedTerms']=this.definedTermArray;
        if(item.name==='Definition'){
          param['rule']='inconsDefinition';
        }else if(item.name==='Words & Phrases'){
            param['rule']='inconsWordPhrase';
        }else{
          param['rule']='inconsSpelling';
        }
        console.log(param)
        fetch("https://apis.digiebooks.in/kronicle/api/assess/",{ 
        method: 'PUT', 
        body:JSON.stringify(param)
        }).then((response) => response.json()) 
        .then((response) => {
        console.log("data from response-->>>>\n",JSON.stringify(response))
        
        this.menuItems.data[inconsistencyIndex].children[index].children=[]
        for(let j=0;j<response.data.length;j++){
          this.menuItems.data[inconsistencyIndex].children[index].children.push(response.data[j]);
        } 
          // this.menuItems.data=response.data.data;
        // console.log( JSON.stringify(this.menuItems.data))
        // this.forceUpdate();
                                           
        })   
        
      }
      this.setState(prevState => ({ 
        [item.name]: !prevState[item.name]
       }));
      

    //  for(let i=0;i<this.menuItems.data.length;i++){
    //    if(this.menuItems.data[i].name ==='Inconsistencies'){
    //     const inconsistencyIndex = i
    //     console.log("indexxxx=",inconsistencyIndex)
    //    }
      
    //  }
     
}

handleChilren(item,index) {
  console.log(index) 
  Word.run(async (context) => {
    var searchResults = context.document.body.search(item.name as string, { ignorePunct: true });
    var mybody=context.document.body;
    context.load(mybody)
    context.load(searchResults, "font"); 
    return context.sync().then(function () {
      mybody.font.highlightColor=null;
      for (var i = 0; i < searchResults.items.length; i++) {
        searchResults.items[i].font.highlightColor = "#FFFF00";
      }
      searchResults.items[0].select();
      searchResults.items[0].font.highlightColor = "#FFFF00";
      return context.sync(); 
    })
  });

  
}
clickText(item,index){
  console.log(item,index)
}

  devider(item){
    if(item==='Defined Terms'|| item==='Unused Defined Terms'||item==='Inconsistent Words and Phrases'||item==='Undefined Terms' || item==='Inconsistent Spellings'||item==='Inconsistent Definitions'){
      return(
        <Divider light />
      )}
      else{
        return(
          <></>
        )
      }
  } 
  removeUnDefinedTerm(_term,i){
    this.menuItems.data[1].children.splice(i,1)
    this.forceUpdate()
    console.log(_term)
    
  }
  removeUndefinedBody(subOption,index){
    if(subOption){
      return(<></>)
    }else{
      return(<div style={{float:'right'}}>
      <HighlightOffIcon sx={{fontSize: '12px',color:'#1976D2',cursor:'pointer',}} onClick={() => this.removeUnDefinedTerm(subOption,index)} />
    </div>)
    }
  }

  crossReferenceBody(cData){
    const { state } = this
    return cData.map((subOption,index) => {
      if (!subOption.children) {
        return (
          <div key={subOption.name} style={{backgroundColor:"#ffffff"}}>
            <Typography onClick={() => this.handleChilren(subOption,index)}  sx={{color:'#000000',fontFamily:'Barlow',fontSize:'12px',marginLeft:'20px',}}>{subOption.name}</Typography>
           
          </div>  
        );
      }      
      return (
        <div  key={subOption.name} > 
         <ListSubheader sx={{lineHeight:'unset'}}>
          <Link underline="none" onClick={() => this.handleClick(subOption,index)} sx={{cursor:'pointer'}}>
        {subOption.name} {subOption?.children.length > 0? `(`+subOption.children?.length+`)` :''}
        {state[subOption.name] ? <Remove sx={{fontSize: '12px'}}/> : <Add sx={{fontSize: '12px'}} />}
          </Link>
          </ListSubheader>
          
          <Collapse in={state[subOption.name]} timeout="auto" unmountOnExit> 
            {this.crossReferenceBody(subOption.children)} 
          </Collapse>
          <Divider light />
          {/* {this.devider(subOption.name)} */}
         
         </div> 
        
      );
    });
  }



  handler(children) {
    // const { classes } = this.props;
    const { state } = this

    return children.map((subOption,index) => {
      if (!subOption.children) {
        return (
          <div key={subOption.name} style={{backgroundColor:"#ffffff"}}>
             {/* <Typography onClick={() => this.handleChilren(subOption,index)}  sx={{color:'#000000',fontFamily:'Barlow',fontSize:'12px',marginLeft:'20px',paddingBottom:'10px'}}>{subOption.definitions}</Typography> */}
            <Typography onClick={() => this.handleChilren(subOption,index)}  sx={{color:'#000000',fontFamily:'Barlow',fontSize:'12px',marginLeft:'20px',}}>{subOption.name}</Typography>
            <Divider light />
          </div>  
        );
      }
      

      
      return (
        
        <div  key={subOption.name} > 
         <ListSubheader sx={{lineHeight:'unset'}} style={subOption.name==='Defined Terms' ||
        subOption.name==='Unused Defined Terms'||subOption.name==='Inconsistent Words and Phrases' || subOption.name==='Undefined Terms'
        ||subOption.name==='Defined Terms not capitalised'||subOption.name==='Inconsistent Spellings' ||subOption.name==='Inconsistent Definitions'?styles.navBar: 
        subOption.name==='Cross-references'||subOption.name==='Linked Defined Terms'?styles.navBar3:styles.navBar2}>
          <Tooltip title={subOption.def} arrow>
           <Stack direction="row" > 
          <Link underline="none" onClick={() => this.handleClick(subOption,index)} sx={{cursor:'pointer'}} style={state[subOption.name]?styles.ColorBlack:styles.ColorBlue}>
        {subOption.name} {subOption?.children.length > 0? `(`+subOption.children?.length+`)` :''}
        {state[subOption.name] ? <Remove sx={{fontSize: '12px'}}/> : <Add sx={{fontSize: '12px'}} />}
          </Link>
              {subOption.name==='Defined Terms' ||subOption.name==='Unused Defined Terms'||subOption.name==='Inconsistent Words and Phrases' || subOption.name==='Undefined Terms'
        ||subOption.name==='Defined Terms not capitalised' || subOption.name==='Inconsistent Spellings'||subOption.name==='Inconsistent Definitions'?<></>:this.removeUndefinedBody(subOption,index)}
          </Stack>
          </Tooltip>
          
          </ListSubheader>
          
          <Collapse in={state[subOption.name]} timeout="auto" unmountOnExit> 
            {this.handler(subOption.children)} 
          </Collapse>
          <Divider light />
          {/* {this.devider(subOption.name)} */}
         
         </div> 
        
      );
    });
  }
 
  
  
  


  render() {
    // const {data } = this.props;
    const { definedTermBody } = this.state
    const {definedDefinition,definedName }= this.state
    
  if(definedTermBody){
    return(
      <>
      <Stack sx={{paddingLeft:'20px'}} direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
       <Link underline="none" onClick={() => this.setState({definedTermBody:false})} sx={{cursor:'pointer',lineHeight:1.5}}>
        All defined Term 
        </Link>
        <Typography>{definedName}</Typography>
        </Stack> 
        {/* <Typography style={{fontSize:'12px'}}>{definedDefinition}</Typography> */}
        <Typography  sx={{color:'#000000',fontFamily:'Barlow',fontSize:'12px',marginLeft:'20px',paddingBottom:'10px'}}>{definedDefinition}</Typography>
        {this.crossReferenceBody(this.crossData)}
      </> 
    )
   }
     
    return ( 
      <div>
        
         {this.handler(this.menuItems.data)}
      </div>
    );
  }
}
