/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
import Link from '@mui/material/Link';



    export interface IndexState {
        // indexAccordionFlag:boolean,
        indexData:any
    }

export default class Index extends React.Component<{},IndexState> {

    constructor(props, context) {
        super(props, context);
        this.state={
            indexData:[{
                heading:"Section 1.1 complex data",
                details:"Complex data",
                index:1345},
                {heading:"Section 1.2 complex data",
                details:"Complex data2",
                index:13456},
                {heading:"Section 1.3 complex data",
                details:"Aggrement",
                index:13457}],
               
        }
      }
      componentDidMount(){ 
        // const dData=localStorage.getItem('dData');
        const indexData=JSON.parse(localStorage.getItem('indexData'));
        this.setState({indexData:indexData})
        console.log(indexData)
        // var payload={}
        // payload['documentData']=dData
        //  fetch("https://s3.jemshid.com/kronicle/api/agreementindex/",{
        //   method: 'PUT', 
        //   body:JSON.stringify(payload)
        //   }).then((response) => response.json()) 
        //     .then((response) => {
        //         // console.log("RESPONSE ",JSON.stringify(response.data))
        //     this.setState({indexData:response.data})
        //     // this.setState({backdropFlag:false})                                  
        //  })   
      }
      
      handleHeader(item){
        console.log(item)
        Word.run(async (context) => {
            var searchResults = context.document.body.search(item, { ignorePunct: true });
            context.load(searchResults,'font'); 
            return context.sync().then(function () {
                console.log(searchResults.items)
            //   for (var i = 0; i < searchResults.items.length; i++) {
            //     // searchResults.items[i].font.highlightColor = "#FFFF00";
            //   }
            searchResults.items[0].select()
              
            //   searchResults.items[0].font.highlightColor = "#FFFF00";
              return context.sync(); 
            })
          });
      }
      indexBody(indexData){

        return indexData?.map((subOption) => {
           
            return (
              <div  key={subOption?.headings} >
                <Link underline="none" onClick={() => this.handleHeader(subOption?.details)} sx={{cursor:'pointer'}}> {subOption.headings}
                  
                </Link>
               </div>
              
            );
          });
      }
      // populate(){
      //     // console.log(this.state.docData)
      //     Word.run(function (context) {
      //       let sections=context.document.sections;
      //       sections.load();
      //       // var body = context.document.body;
      //       // body.clear();
      //       // body.insertFileFromBase64(this.state.docData,Word.InsertLocation.start)
      //       return context.sync().then(function () {
      //           sections.items.forEach(function(section){
      //             section.body.clear()
      //             section.getHeader("Primary").clear()
      //             section.getHeader("FirstPage").clear()
      //             section.getHeader("EvenPages").clear()

      //             section.getFooter("Primary").clear()
      //             section.getFooter("FirstPage").clear()
      //             section.getFooter("EvenPages").clear()
      //           })
      //           context.document.body.insertFileFromBase64(this.state.docData,"Start")
      //           return context.sync();
      //       });
      //   })
      //   .catch(function (error) {
      //       console.log("Error: " + JSON.stringify(error));
      //       if (error instanceof OfficeExtension.Error) {
      //           console.log("Debug info: " + JSON.stringify(error.debugInfo));
      //       }
      //   });
      // }
  render() {
    
    return ( 
        <div>
               {this.indexBody(this.state.indexData)}
               
        </div>
    );
  }
}
