/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import AssemblerForm from '../components/forms/AssemblerForm';
import NeutralForm from '../components/forms/NeutralForm';
import PassiveForm from '../components/forms/PassiveForm';
import {aggressiveFormat} from './data/aggressiveBase64';
import {neutralFormat} from './data/neutralBase64';
import {passiveFormat} from './data/passiveBase64';
import {mandateFormat} from './data/mandateBase64';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import MandateForm1 from '../components/forms/mandateForm1';
import MandateForm2 from '../components/forms/mandateForm2';


interface FilmOptionType {
    name: string;
    value: string; 
  }
  const templates: readonly FilmOptionType[] = [
    { name:'Non-Disclouser Agreement (NDA)',value:'NDA'},
    { name:'Master Services Agreement (MSA)',value:'MSA'},
    { name:'Mandate Agreement',value:'MA'}
  ]
export interface AssemblerState {
    docData:string;
    docTypeValue:string;
    intensityValue:string;
    dataPopulated:boolean;
    selectedDocumentType:string;
    salutation:string,
    checkedItems: any,
    scopesArrayToInsert:any,
    remuneration:string,
    mandateFilled:boolean,
    indexSerch:number
}
export interface docsOptionType {
    name: string;
    value: string;
  }    
export default class Assembler extends React.Component<{},AssemblerState> {
    p35='Fees: The monthly retainer Fee starting from <<Start Month>> is Rs. <<Amount>>/- (in words, Rupees <<AmountinWords>> only) subject to the number of hours of engagement not exceeding <<Working hours>> hours per month.'
              
    paragraph2:string='Monthly Retainer Engagement –'
    scopeOfServiceArray=[{'key':'Legal advisory services'},
                          {'key':'Giving opinions on contractual issues'},
                          {'key':'Advising the Board of Directors'},
                          {'key':'Vetting drafts'},
                          {'key':'Advice on company compliances and regulatory filings'},
                          {'key':'Drafting Agreements'},
                          {'key':'General Advice'},
                          {'key':'Reviewing of Donor Agreements'},
                          {'key':'Advise on FCRA matters'},
                          {'key':'Advise on CSR specific matters/ CSR obligations under Companies Act'}
                        ]
    constructor(props, context) {
        super(props, context);
        this.state={ 
            dataPopulated:false,
            intensityValue:'',
            docTypeValue:'',
             docData:'',
             selectedDocumentType:'',
             salutation:'',
             checkedItems: new Map(),
             scopesArrayToInsert:[],
             remuneration:'Time Based Billing',
             mandateFilled:false,
             indexSerch:16
            }
        
      }
      componentDidMount(){
        
      }

  render() {
    const populate=async()=>{
        await Word.run(async (context) =>{
          var body = context.document.body;
          body.clear();
          console.log(JSON.stringify(this.state.docData))
          body.insertFileFromBase64(this.state.docData,Word.InsertLocation.start)
          this.setState({dataPopulated:true})
          await context.sync(); 
          
        })
        .catch(function (error) {
            console.log("Error: ", JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });  
      
    }

    
    const handleChangeTemplates = (_event,option) => {
        console.log(option)
        this.setState({selectedDocumentType:option})
        if(option==='Mandate Agreement'){
          this.setState({docData:mandateFormat})
          populate()

        }
        
      }; 
    const handleChangeDocumentType = (_event: React.ChangeEvent<HTMLInputElement>) => {
        // setValue((event.target as HTMLInputElement).value);
        console.log(_event.target.value)
        this.setState({docTypeValue:_event.target.value});
        
      };

    const next=()=>{
      this.setState({mandateFilled:!this.state.mandateFilled})
        Word.run(async (context) => {
          var searchResults = context.document.body.search('<<Salutation>>', { ignorePunct: true });
          context.load(searchResults, 'text');
          await context.sync();
          console.log(searchResults)
          searchResults.items[0].insertText(this.state.salutation as string, Word.InsertLocation.replace);
          await context.sync();

      });
    }
    const changeSalutations = (_event: React.ChangeEvent<HTMLInputElement>) => {
        // setValue((event.target as HTMLInputElement).value);
        console.log(_event.target.value)
        this.setState({salutation:_event.target.value});
      //   Word.run(async (context) => {
      //     var searchResults = context.document.body.search('<<Salutation>>', { ignorePunct: true });
      //     context.load(searchResults, 'text');
      //     await context.sync();
      //     console.log(searchResults)
      //     searchResults.items[0].insertText(this.state.salutation as string, Word.InsertLocation.replace);
      //     await context.sync();

      // });
    }
    const changeRemuneration = (_event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(_event.target.value)
      this.setState({remuneration:_event.target.value});
      if(_event.target.value==='Time Based Billing'){
        Word.run(async (context) => {
          var searchResults = context.document.body.search('Monthly Retainer Engagement', { ignorePunct: true });
          context.load(searchResults, 'text,font');
          await context.sync();
          // console.log(searchResults.items[0])
          // for(let i=0;i<this.paragraph1.length;i++){
          //searchResults.items[0].insertText(i.p as string, Word.InsertLocation.replace);
          // }
          // await context.sync();
          // Create a proxy object for the paragraphs collection.
        var paragraphs = context.document.body.paragraphs;
        context.load(paragraphs, 'text, font/size')
        await context.sync();
        return context.sync().then(function () {
          console.log(JSON.stringify(paragraphs.items))
          var p=searchResults.items[0].insertText('Time Based Billing', Word.InsertLocation.replace);
          p.font.bold=true;
          var index=paragraphs.items.findIndex(x=>x.text==="Fees: The monthly retainer Fee starting from <<Start Month>> is Rs. <<Amount>>/- (in words, Rupees <<AmountinWords>> only) subject to the number of hours of engagement not exceeding <<Working hours>> hours per month.")
          paragraphs.items[index].insertText('Our fees are generally determined by the time spent by each lawyer or other service provider involved in this engagement and the hourly billing rates assigned to each such person. As at the date of this letter, the hourly rates of the lawyers who will be assigned on this matter are as follows:',Word.InsertLocation.replace)
          paragraphs.items[index+1].insertText('Partner:INR <<Partner Hourly Fee>> per hour',Word.InsertLocation.replace)
          paragraphs.items[index+2].insertText('Sr. Associate:INR <<Sr. Associate Hourly Fee>> per hour ',Word.InsertLocation.replace)
          paragraphs.items[index+3].insertText('Associate:INR <<Associate Hourly Fee>> per hour',Word.InsertLocation.replace)
          paragraphs.items[index+4].insertText('Jr. Associate:INR << Jr. Associate Hourly Fee>> per hour ',Word.InsertLocation.replace)
        }) 

      });
    }else{
      Word.run(async (context) => {
      var paragraphs = context.document.body.paragraphs;
      context.load(paragraphs, 'text, font/size')
      var searchResults = context.document.body.search('Time Based Billing', { ignorePunct: true });
      context.load(searchResults, 'text,font');
      return context.sync().then(function () { 
        console.log(paragraphs)
        var p=searchResults.items[0].insertText('Monthly Retainer Engagement', Word.InsertLocation.replace);
          p.font.bold=true;
          var index=paragraphs.items.findIndex(x=>x.text==="Our fees are generally determined by the time spent by each lawyer or other service provider involved in this engagement and the hourly billing rates assigned to each such person. As at the date of this letter, the hourly rates of the lawyers who will be assigned on this matter are as follows:")
        paragraphs.items[index].insertText('Fees: The monthly retainer Fee starting from <<Start Month>> is Rs. <<Amount>>/- (in words, Rupees <<AmountinWords>> only) subject to the number of hours of engagement not exceeding <<Working hours>> hours per month.',Word.InsertLocation.replace)
        paragraphs.items[index+1].insertText('Excess Hours: If the services that Client requests in a given month exceed <<Working hours>> hours, APJ will provide all of the services that Client requested, and Client agrees to pay for the excess time at a blended retainer rate of Rs. <<Amount2>> (Rupees <<AmountinWords2>> only) per hour.',Word.InsertLocation.replace)
        paragraphs.items[index+2].insertText('No Carry Forward: APJ will invoice all the additional time separately at the end of the month in which those hours were used. The hours not used within a given month do not carry forward to the following month.',Word.InsertLocation.replace)
        paragraphs.items[index+3].insertText('Carry Forward: APJ will invoice all excess time separately at the end of the quarter in which those hours were used. The hours not used within a given month will be carried forward to the following month, provided that it is within a calendar quarter. If the services that Client requests in a given calendar quarter exceed <<Working hours>> hours, APJ will provide all of the services that Client requested, and Client agrees to pay for the excess time at the general non-retainer rate of Rs. <<Amount2>> (Rupees <<AmountinWords2>> only) per hour. The billing details and time sheets pertaining to the extra hours in a calendar quarter will require an approval from Client, prior to raising of invoice.',Word.InsertLocation.replace)
        paragraphs.items[index+4].insertText('Modification: If Client observes that the number of hours used is regularly exceeding or falling short of the number of hours specified in this agreement, Client may wish to negotiate a new retainer agreement with a greater or lesser number of hours, and APJ will be glad to accommodate such a request. ',Word.InsertLocation.replace)
        
      })

    });
    } 
  }
    const handleChangeIntesity=(_event: React.ChangeEvent<HTMLInputElement>)=>{
      console.log(_event.target.value)
      this.setState({intensityValue:_event.target.value});
      if(_event.target.value==='aggressive'){
        this.setState({docData:aggressiveFormat})
      }else if(_event.target.value==='neutral'){
        this.setState({docData:neutralFormat})
      }
      else if(_event.target.value==='passive'){
        this.setState({docData:passiveFormat})
      } 
    }
    const handleChangeCheck=(e: React.ChangeEvent<HTMLInputElement>)=>{
      const item = e.target.name;
      // let flag=e.target.checked;
     // const isChecked = e.target.checked;
     // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
      
    //  if(flag){
        if(!this.state.scopesArrayToInsert.find(i=>i.key===item)){
             this.setState({scopesArrayToInsert:[...this.state.scopesArrayToInsert,{"key":item}]})
             console.log(this.state.scopesArrayToInsert)
          //    Word.run(async (context) => {
          //     var paragraphs = context.document.body.paragraphs;
          //     context.load(paragraphs, 'text, font/size')
          //     var searchResults = context.document.body.search('<<scopeOfServices>>', { ignorePunct: true });
          //     context.load(searchResults, 'text');
          //     await context.sync();
          //     var index=paragraphs.items.findIndex(x=>x.text==="<<scopeOfServices>>")
          //     // for(let i=0;i<this.state.scopesArrayToInsert.length;i++){
          //     //   paragraphs.items[index+1].insertText(i.key,Word.InsertLocation.replace)
          //     // }
          //     this.state.scopesArrayToInsert.forEach(element => { 
          //       paragraphs.items[index+1].insertText(element.key,Word.InsertLocation.replace)
    
          //     });
          //     await context.sync(); 
    
          // });
           Word.run(async (context) => {
            let paragraphs = context.document.body.paragraphs;
            paragraphs.load("$none"); //We need no properties.
        
            await context.sync();
            var list = paragraphs.items[this.state.indexSerch].startNewList(); //Indicates new list to be started in the second paragraph.
            
            list.load("$none"); //We need no properties.
        
            await context.sync();
        
            //To add new items to the list use start/end on the insert location parameter.
            // list.insertParagraph('New list item on top of the list', 'Start');
            let paragraph = list.insertParagraph(item, Word.InsertLocation.after);
            
            paragraph.listItem.level = 0; //Sets up list level for the lsit item.
            
            //To add paragraphs outside the list use before/after:
          //   this.state.scopesArrayToInsert.forEach(element => { 
          //     list.insertParagraph(element.key, 'After');
          // });
            const index=this.state.indexSerch+1
            console.log(index)
            this.setState({indexSerch:index}) 
            await context.sync(); 
        }); 
         }
    //  } 
    else{
         let filteredArray=this.state.scopesArrayToInsert.filter(itm=>itm.key !==item)
         this.setState({scopesArrayToInsert:filteredArray})
         console.log(this.state.scopesArrayToInsert)
         Word.run(async (context) => {
         let paragraphs = context.document.body.paragraphs;
            paragraphs.load("$none");
            await context.sync();
             paragraphs.items[this.state.indexSerch].delete()
            await context.sync();
         })
     }
      
   }
    const getDocumentType=()=>{
      if(!this.state.dataPopulated && this.state.selectedDocumentType==='Non-Disclouser Agreement (NDA)'){
        return( 
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Type of NDA</Typography></FormLabel>
                <RadioGroup row
                  aria-label="typeofdocs"
                  name="controlled-radio-buttons-group"
                  value={this.state.docTypeValue}
                  onChange={handleChangeDocumentType}
                >
                  <FormControlLabel value="One-Party" label={<Typography sx={{fontSize:'12px'}}>One-Party</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  

                  }}/>}  />
                  <FormControlLabel value="Mutual" label={<Typography sx={{fontSize:'12px'}}>Mutual</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                </RadioGroup>
              </FormControl>
              
            </div>
        )
          }else if(!this.state.dataPopulated && this.state.selectedDocumentType==='Mandate Agreement'){
                  return(
                    <div>
              {/* <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Salutation</Typography></FormLabel>
                <RadioGroup row
                  aria-label="typeofdocs"
                  name="controlled-radio-buttons-group"
                  value={this.state.salutation}
                  onChange={changeSalutations}
                >
                  <FormControlLabel value="Dear Sir" label={<Typography sx={{fontSize:'12px'}}>Dear Sir</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  

                  }}/>}  />
                  <FormControlLabel value="Dear Madam" label={<Typography sx={{fontSize:'12px'}}>Dear Madam</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                  <FormControlLabel value="Dear <<Attn:>>" label={<Typography sx={{fontSize:'12px'}}>Dear Attn</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                </RadioGroup>
              </FormControl> */}
            </div>
                  )
                }else{
                  return(
                  <></>
                  )
                }
    }
    const getDocumentIntensity=()=>{
      if(!this.state.dataPopulated && this.state.selectedDocumentType==='Non-Disclouser Agreement (NDA)'){
      return(
        <div>
              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Intensity of the document</Typography></FormLabel>
                <RadioGroup row
                  aria-label="intensity"
                  name="controlled-radio-buttons-group2"
                  value={this.state.intensityValue}
                  onChange={handleChangeIntesity}
                >
                  <FormControlLabel value="passive" control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>} label={<Typography sx={{fontSize:'12px'}}>Passive</Typography>} />
                  <FormControlLabel sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }} value="neutral" control={<Radio  size="small" />} label={<Typography sx={{fontSize:'12px'}}>Neutral</Typography>}/>

                  <FormControlLabel sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }} value="aggressive" control={<Radio  size="small" />} label={<Typography sx={{fontSize:'12px'}}>Aggressive</Typography>} />
                </RadioGroup>
              </FormControl>
            </div>
      )
       }else{
         return(
           <></>
         )
       }
    }
    const getEditControls=()=>{
      return(

           <></>
      )
    }
    
    const getButton=()=>{
      if(!this.state.dataPopulated){
      return(
        <Button variant="contained" onClick={populate} disabled={this.state.intensityValue === '' && this.state.salutation===''} sx={{ mt: 1, mr: 1 }}>populate</Button>
      )}
      else{
        return(
         <></>
        )
      }
    }
    const returnCheckboxes=(s)=>{
      return s.map((p) => {
        return (
          <label key={p.key} style={{padding:0,margin:0}}>
           
          <Checkbox
          name={p.key}   onChange={(e) => handleChangeCheck(e)} size="small" />
           <Typography variant="caption" gutterBottom style={{fontFamily:"barlow",lineHeight:"unset"}}>
           {p.key}
          </Typography> 
          
        </label>
         
        );
    });
    }
    const getFormFields=()=>{
      if(this.state.dataPopulated && this.state.intensityValue==='aggressive'){
      return(
        <AssemblerForm />
      )}
      else if(this.state.dataPopulated && this.state.intensityValue==='neutral'){
        return(
          <NeutralForm />
        )}
        else if(this.state.dataPopulated && this.state.intensityValue==='passive'){
          return(
            <PassiveForm />
          )}
          else if(this.state.dataPopulated && this.state.selectedDocumentType==='Mandate Agreement'){
            
              if(!this.state.mandateFilled){
                return(
              <Stack spacing={5}>
              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Salutation</Typography></FormLabel>
                <RadioGroup row
                  aria-label="typeofdocs"
                  name="controlled-radio-buttons-group"
                  value={this.state.salutation}
                  onChange={changeSalutations}
                >
                  <FormControlLabel value="Dear Sir" label={<Typography sx={{fontSize:'12px'}}>Dear Sir</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  

                  }}/>}  />
                  <FormControlLabel value="Dear Madam" label={<Typography sx={{fontSize:'12px'}}>Dear Madam</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                  <FormControlLabel value="Dear <<Attn:>>" label={<Typography sx={{fontSize:'12px'}}>Dear Attn</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Scope of services</Typography></FormLabel>
                <Stack spacing={0}>
              {returnCheckboxes(this.scopeOfServiceArray)}
              </Stack>
              </FormControl> 


              <FormControl component="fieldset" >
                <FormLabel component="legend"><Typography variant="caption" display="block" sx={{fontSize:"9px"}}>Remuneration</Typography></FormLabel>
                <RadioGroup row
                  aria-label="typeofdocs"
                  name="controlled-radio-buttons-group"
                  value={this.state.remuneration}
                  onChange={changeRemuneration}
                >
                  <FormControlLabel value="Time Based Billing" label={<Typography sx={{fontSize:'12px'}}>Time Based Billing</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  

                  }}/>}  />
                  <FormControlLabel value="Monthly Retainer Engagement" label={<Typography sx={{fontSize:'12px'}}>Monthly Retainer Engagement</Typography>} control={<Radio  size="small" sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 12,
                    },
                  }}/>}  />
                 
                </RadioGroup>
              </FormControl>
              <Button variant="contained" onClick={next} disabled={this.state.salutation === '' || this.state.scopesArrayToInsert.length===0} sx={{ mt: 1, mr: 1 }}>Next</Button>

            </Stack>
                )
              }
              else if(this.state.mandateFilled && this.state.remuneration==='Time Based Billing'){
                return(
                  <MandateForm1 />
                )
              }else if(this.state.mandateFilled && this.state.remuneration==='Monthly Retainer Engagement'){
                return(
                  <MandateForm2 />
                )
              }else{
                return(
                  <></>
                )
              }
            }
      else {
        return(
        <></>
        )
      }
    }
    const autoComplete=()=>{
      if(!this.state.dataPopulated){
      return(
        <Autocomplete  sx={{ width: '100%', maxWidth: 280,padding:'6px' }}
        freeSolo
        size="small" 
        disableClearable
        options={templates.map((option) => option.name)}
        onChange={handleChangeTemplates}
        renderInput={(params) => (
          <TextField 
            {...params}
            label="Choose type of document"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      )
      }else{
        return(
          <></>
        )
      }
    }
    
    return (

        <div style={{display:"flex",flexDirection:'column',padding:"10px"}}> 
        {autoComplete()}
           
      
      {getDocumentType()}
      {getDocumentIntensity()}
      {getEditControls()}
      {getButton()}
      {getFormFields()}
      
        </div>
    );
  }
}
