/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import * as React from "react";
import { useWindowScroll } from "react-use";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function ScrollToTop() {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = React.useState(false);
    React.useEffect(() => {
        if (pageYOffset > 0) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const toTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return (
            <></>
        )
    }

    return (
       
        <div className="scroll-to-top cursor-pointer text-center" onClick={toTop}>
        {<ExpandLessIcon className="icon" />}
        </div>
        
    );
}