/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import * as React from "react";
import { useWindowScroll } from "react-use";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ScrollToTop() {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = React.useState(false);
    React.useEffect(() => {
        if (pageYOffset > 0) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const toBottom = () => window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });

    if (!visible) {
        return (
            <></>
        )
    }

    return (
       
        <div className="scrollBottom" onClick={toBottom}>
        {<ExpandMoreIcon />}
        </div>
        
    );
}