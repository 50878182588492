/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Index from "../components/Index";
import Additor from "../components/Additor";
import Assess from "../components/Assess";
import ScrollToTop from "../components/ScrollToTop"
import ScrollToBottom from "../components/ScrollToBottom"
// import Add from '@mui/icons-material/Add';
// import Remove from '@mui/icons-material/Remove';
import Assembler from '../components/Assembler';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import ListItem from '@mui/material/ListItem';

import ListSubheader from '@mui/material/ListSubheader';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Tooltip from '@mui/material/Tooltip';



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
      flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& ':{
    minHeight: '40px',
    maxHeight:'40px',
    paddingLeft:0
    
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function MainPage() { 
  const [expanded, setExpanded] = React.useState<string | false>('');
  // const { history } = props;

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const mainBody =()=>{
    // if(expanded === 'panel1'){
    //   return(
    //       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
    //     <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={expanded?<Remove />:<Add />}>
    //       <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Agreement Index</Typography>
    //     </AccordionSummary>
    //     <AccordionDetails sx={{padding:'0 0 0 20px' }}>
    //       <Index />
    //     </AccordionDetails>
    //   </Accordion>
    //   )
    // }else if(expanded === 'panel2'){
    //   return(
    //     <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
    //     <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={expanded?<Remove />:<Add />}>
    //       <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Assess</Typography>
    //     </AccordionSummary>
    //     <AccordionDetails sx={{padding:0}}>
    //       <Assess />
    //     </AccordionDetails>
    //   </Accordion>
    //   )
    // }else if(expanded === 'panel3'){
    //   return(
    //     <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
    //     <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={expanded?<Remove />:<Add />}>
    //       <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Additor</Typography>
    //     </AccordionSummary>
    //     <AccordionDetails sx={{padding:0}}>
    //       <Additor />
    //     </AccordionDetails>
    //   </Accordion>
      
    //   )
    // }
    // else if(expanded === 'panel4'){
    //   return(
    //     <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
    //         <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={expanded?<Remove />:<Add />}>
    //           <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Assembler</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails sx={{padding:0}}>
    //           <Assembler />
    //         </AccordionDetails> 
    //       </Accordion>
    //   )
    // }
    // else{
      return(
        <div> 
        <ListSubheader sx={{padding:0,margin:0,lineHeight:1.2}}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ListAltIcon sx={{ fontSize: '40px' }} />}>
            <Tooltip title='Contract navigation tool – Click to go to relevant section.' arrow>
              <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Agreement Index</Typography>
            </Tooltip>
            </AccordionSummary>
            <AccordionDetails sx={{padding:'0 0 0 20px',paddingBottom: '60px !important' }}>
              <Index />
            </AccordionDetails>
          </Accordion>
          </ListSubheader>

          <ListSubheader sx={{padding:0,margin:0,lineHeight:1.2}}>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ManageSearchIcon sx={{ fontSize: '40px' }}/>}>
            <Tooltip title='Double click on highlighted issue(s) to address potential errors / inconsistencies.' arrow>
              <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Assess</Typography>
            </Tooltip>
            </AccordionSummary>
            <AccordionDetails sx={{padding:0,paddingBottom: '60px !important'}}>
              <Assess />
            </AccordionDetails>
          </Accordion> 
          </ListSubheader>

          <ListSubheader sx={{padding:0,margin:0,lineHeight:1.2}}>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<NoteAddIcon sx={{ fontSize: '40px' }}/>}>
            <Tooltip title='Insert standard contract language / pre-approved clause(s).' arrow>
              <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Additor</Typography>
            </Tooltip>
            </AccordionSummary>
            <AccordionDetails sx={{padding:0}}> 
              <Additor />
            </AccordionDetails>
          </Accordion>
          </ListSubheader>
          <ListSubheader sx={{padding:0,margin:0,lineHeight:1.2}}>
          
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<LibraryBooksIcon sx={{ fontSize: '40px' }}/>}>
            <Tooltip title='Generate fresh contracts from pre-approved standardized templates.' arrow>
              <Typography sx={{fontFamily:'Barlow',fontSize:'17px',fontWeight:'500'}}>Assembler</Typography>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails sx={{padding:0}}>
              <Assembler />
            </AccordionDetails>
          </Accordion>
          </ListSubheader>

          

        </div>
      )
    // }
  };

  return (
    <div>
      <ScrollToBottom />
      {mainBody()}
      <ScrollToTop />
    </div>
  );
}

