/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import * as React from "react";
// import { Redirect} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { IClause } from './models/clouse.models';
import Checkbox from '@mui/material/Checkbox';
// import { DefaultButton } from '@fluentui/react/lib/Button';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(255, 255, 255, .05)',
  '& ':{
    minHeight: '30px',
    maxHeight:'30px'
  }
})); 

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
 


export interface AppState {
//    generalClauses: IClause[],
   clientClauses:IClause[],
   checkedItems: any ,
   clauseID: any,
   alignment:any,
   intialHeader:any,
   finalArrayToInsert:any,
   headerFlag:boolean,
   level:Number,
   accordianFlag:boolean
  
}

  
export default class SellSideClause extends React.Component<{},AppState> {
  // state = {
  //   redirect: false
  // }
  // generalClauses:any[]
  baseUrl = 'https://webapidev.lexserve.in/api/';
  clauses: IClause[];
//   clientClauses: IClause[];
  //  generalClauses: IClause[];
  clause: IClause;
  
  btnArray:[{name:'All',value:0},{name:'Preferred',value:0},{name:'Agressive',value:0}]
  message = '';
  clauseVersion = 1;
  // alignment="0";

  clausesData: any;
  clauseParagraph:any
  clauseParagraphCopy:any
  
  
  constructor(props, context) {
    super(props, context);
    this.state={ 
    //   generalClauses:[],
      clientClauses:[],
      checkedItems: new Map(),
      clauseID: 'abc',
      alignment:"0",
      intialHeader:'',
      finalArrayToInsert:[],
      headerFlag:false,
      level:0,
      accordianFlag:false
    }
    
  }

   componentDidMount() {
  //    axios.get(this.baseUrl + 'clauses')
  // .then(response => {
  //     // console.log(JSON.stringify(response.data))
  //     this.clauses = response.data;
  //      let gClauses = this.clauses.filter(x => x.clauseType == 1);
  //     this.clientClauses = this.clauses.filter(x => x.clauseType == 2);
  //     this.setState({generalClauses:gClauses})
  //     // console.log("GENERAL CLAUSES\n",JSON.stringify(this.generalClauses))
  // })
  // .catch(error => {
  //   console.log(error);
  // });
    
  }
  handleClick(){
    this.setState({accordianFlag:!this.state.accordianFlag})
    axios.get(this.baseUrl + 'clauses')
  .then(response => {
      // console.log(JSON.stringify(response.data))
      this.clauses = response.data;
    //    let gClauses = this.clauses.filter(x => x.clauseType == 1);
       let cClauses=this.clauses.filter(x => x.clauseType == 2);
      this.setState({clientClauses:cClauses})

      // this.generalClauses=gClauses;
      this.forceUpdate()
      // console.log("GENERAL CLAUSES\n",JSON.stringify(this.generalClauses))
  })
  .catch(error => {
    console.log(error); 
  });
  }
  

  
  


  render() { 
    const handleChange = (event,option) => {
      console.log(event.target.value)
      const dataIndex = this.state.clientClauses.findIndex((i) => i.name===option);
      let dataClause=this.state.clientClauses[dataIndex]
      // let clauseList=dataClause.clauseLists
     console.log(JSON.stringify(this.state.clientClauses))
      this.clauseParagraph=dataClause.clauseLists
      this.clauseParagraphCopy=dataClause.clauseLists
      this.setState({intialHeader:dataClause.paragraphText,finalArrayToInsert: [],headerFlag:false})
      renderParagraph(this.clauseParagraph)
    };
    const handleChangeHeader=(e,f)=>{
      console.log(e.target.value)
      let flag=f
      console.log(flag)
      if(flag){
        this.setState({headerFlag:true})
      }else{
        this.setState({headerFlag:false})
      }
    }
    const handleChangeCheck=(e: React.ChangeEvent<HTMLInputElement>,lev)=>{
       const item = e.target.name;
       let cID:string =e.target.id
       let flag=e.target.checked;
      // const isChecked = e.target.checked;
      // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        this.setState({clauseID:cID})
       
      if(flag){
         if(!this.state.finalArrayToInsert.find(i=>i.paragraphText===item)){
              this.setState({finalArrayToInsert:[...this.state.finalArrayToInsert,{"paragraphText":item,"level":lev}]})
              console.log(this.state.finalArrayToInsert)
          }
      } else{
          let filteredArray=this.state.finalArrayToInsert.filter(itm=>itm.paragraphText !==item)
          this.setState({finalArrayToInsert:filteredArray})
          console.log(this.state.finalArrayToInsert)
      }
       
    }
    const insertParagraphText=()=>{
      console.log(this.state.clauseID)
      console.log(this.state.finalArrayToInsert)
      console.log(this.state.headerFlag)
      // let isClientClause=true
      return Word.run(async context => {
        // let clauseService = new ClauseService();
        // let _clauseVersion = this.clauseVersion;
        
        // if (isClientClause)
        //   _clauseVersion = 2;
  
        // let that = this;
        // await axios.get(this.baseUrl + 'clauses/' + this.state.clauseID)
        //   .then(function (response) {
        //     // handle success
        //     console.log(JSON.stringify(response))
        //     that.clause = response.data;
        //   })
        //   .catch(function (error) {
        //     // handle error
        //     //console.log(error);
        //     that.message = error;
        //   })
        //   .then(function () {
        //     // always executed
        //   });
  
        let pargraphToReplace = context.document.getSelection().paragraphs.getFirst();
        
        let newList = pargraphToReplace.insertParagraph(this.state.intialHeader, Word.InsertLocation.after)
        // if(!this.state.headerFlag){
        //    newList.load();
        // }
        newList.load();
        pargraphToReplace.delete();
  
        await context.sync();
        
        
        let createdList: Word.List;
  
        if (!newList.isListItem) {
          createdList = newList.startNewList();
          createdList.setLevelNumbering(0, "Arabic", [0, "."]);
        }
        else
          createdList = newList as any as Word.List;
  
        await context.sync();
        createdList.load();
  
        if (!newList.isListItem) {
          createdList.setLevelNumbering(1, "Arabic", [0, ".", 1,]);
          createdList.setLevelNumbering(2, "Arabic", [0, ".", 1, ".", 2]);
        }
  
        let tempParagraph = newList;
  
        this.state.finalArrayToInsert.forEach(element => {
          let subList1 = tempParagraph.insertParagraph(element.paragraphText,
            Word.InsertLocation.after);
          subList1.listItem.level = element.level;
          tempParagraph = subList1;
        });
  
        await context.sync();
  
        let pRange = newList.getRange(Word.RangeLocation.content);
        pRange.font.bold = true;
  
        await context.sync();
      });
    }
    const renderParagraph=(paragraph)=>{
      if(this.clauseParagraph){
      return paragraph.map((p) => {
          return (
            <label key={p.paragraphText}>
            <Checkbox
            name={p.paragraphText} id={p.clauseId} checked={this.state.checkedItems.get(p.paragraphText)} onChange={(e) => handleChangeCheck(e,p.level)} />
             <Typography variant="caption" gutterBottom style={{fontFamily:"barlow",lineHeight:"unset"}}>
             {p.paragraphText}
            </Typography>
          </label>
           
          );
      }
      );
    }

    }
    const buttonDiv=()=>{
      if(this.state.finalArrayToInsert.length!==0){
      return(
        <Button variant="contained" onClick={insertParagraphText} sx={{ width: '100%', maxWidth: 300 }}>Insert</Button>
      );
      }else{
        return(
        <div></div>
        )
      }
    }
    const applyClause=(event,newAlignment)=>{
      console.log(event.target.value)
      const value=newAlignment
      this.setState({alignment:value})
      const v=Number(value)
      this.clauseParagraph=this.clauseParagraphCopy;
      if(v !==0){
        this.clauseParagraph=this.clauseParagraphCopy.filter(x => x.clauseVersion === v)
      }
      
    }
    const btnGrp=()=>{
      if(this.clauseParagraph){
        return (
          <ToggleButtonGroup color="primary" value={this.state.alignment} exclusive onChange={applyClause}size="small">
            <ToggleButton value="0" sx={{textTransform:'none'}}>All</ToggleButton>
            <ToggleButton value="1" sx={{textTransform:'none'}}>Preferred</ToggleButton>
            <ToggleButton value="2" sx={{textTransform:'none'}}>Aggressive</ToggleButton>
          </ToggleButtonGroup>
        );
      }else{
        return(
        <></>
        );
      }
    }
    const initHeader=()=>{
      if(this.clauseParagraph){
        return (
          <label>
            <Checkbox onChange={handleChangeHeader} inputProps={{ 'aria-label': 'controlled' }}/>
            <Typography variant="caption" gutterBottom sx={{fontWeight:600}}>
             {this.state.intialHeader}
            </Typography>
          </label>
        );
      }else{
        return(
        <></>
        );
      }
    }
    return (
      <Accordion onChange={() => this.handleClick()} sx={{boxShadow:'unset'}}>
    <AccordionSummary
      expandIcon={this.state.accordianFlag?<Remove />:<Add />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{margin:"5px 0",}}
    >
      <Typography sx={{fontFamily:'Barlow',fontSize:'12px',fontWeight:500}}>Sell-Side Clauses</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{padding:'2px' }}>
    <Stack spacing={2} sx={{ width: 300 ,marginTop:'5px'}}>
      
      <Autocomplete  sx={{ width: '100%', maxWidth: 280,padding:'6px' }}
        freeSolo
        size="small" 
        disableClearable
        options={this.state.clientClauses.map((option) => option.name)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField 
            {...params}
            label="Search for a Clause"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        
      />
      <Stack spacing={2} direction="row">
          {btnGrp()}
      </Stack>
      {initHeader()}
      {renderParagraph(this.clauseParagraph)}
      {buttonDiv()}
    </Stack>
    
    </AccordionDetails>
  </Accordion>
    
    );
  }
}
